export const links = {
    linkedIn: 'https://www.linkedin.com/in/nimit-johri/',
    facebook: 'https://www.facebook.com/nimit.johri/',
    instagram: 'https://www.instagram.com/nimitjohri/',
    gitHub: 'https://github.com/nimitjohri2',
    quotesAPI: 'https://api.quotable.io/random'
}

export const intro = 'Explorer, deep thinker, foodie, compassionate, curious and a technology enthusiast. Delivering full stack solutions with ReactJS, NodeJS, Express, SQL and NoSQL and more.'

export const summary = {
    paragraphs: [
        'Explorer, deep thinker, foodie, compassionate, curious and a technology enthusiast. I was born on the Indian subcontinent, in the most populated town of the most populated state of the most populated country in the world! Growing up around so many people taught me collabration, patience and compassion. My experiences living in 8 cities across 2 continents, has taught me to embrace change and reinvent myself as rqeuired. Curiosity has always pushed me gently towards science and has trained my analytical mind to seek answers and provide solutions',
        'With a Masters in Computer Sceince and ' + (new Date(new Date() - new Date('July 2016')).getFullYear() - 1970) + '+ years of industry experience as a seasoned software engineer, I am looking forward to growing my network and contributing to significant projects, playing my part in making the world a better place. In my short term goals I see myself working with an inspiring team as a senior engineer or lead role and improving my problem solving skills along with developing new managerial and leadership skills. Eventually, in my long term goals I see myself as a product manager for a large scale project, impacting and improving the lives of masses',
        'I am available in the market, ready to reinvent myself yet again and mold into fitting the new requirements to my best capability. Open to relocate anywhere across the country, I am patiently waiting to see where destiny takes me this time and a new city to call home, for the 9th time.'
    ],
    contact: {
        phone: '+1 (267) 516 8919',
        email: 'nimit.johri@temple.edu'
    }
}

export const quote = {
    author: 'Bhagwad Gita',
    content: 'You are what you believe in. You become that which you believe you can become'
}

export const experiences = [
    {
        icon: 'odu.png',
        Employer: 'Old Dominion University',
        Position: 'Senior Software Engineer',
        from: 'Sept 2020',
        to: 'present',
        summary: 'Currently, I am working as a Senior Software Engineer (Full Stack) with Old Dominion University, Norfolk VA, where I contribute at each step of the SDLC starting from stakeholder interaction and requirement gathering to design, architect, implement and deliver solutions iteratively. I have also started supervising 2 software engineers, developing managerial and leadership skills. I develop full stack solutions with ReactJS, NodeJS, Express, SQL and NoSQL in a primarily MERN stack, creating our own authenticated APIs and exposing webhooks to third parties and vendors along with consuming third party APIs. We have embarked on a journey to go cloud native with AWS and I have been contributing to the design and architecture of our new and existing applications in the cloud and have been developing skripts to transition existing appplications and databases into insfrastructure as code, picking up the AWS CCP certification along the way',
        details: [
            'Supervising a team of two software engineers, building leadership skills and increasing team synergy',
            'Currently contributing to the university effort to go cloud native with AWS, architecting API as infrastructure-as-code, developing React applications to run cloud native with AWS Amplify and implement NodeJS express as serverless in AWS Lambda.',
            'Reengineered PHP based legacy application to ReactJS with NoSQL application database and NodeJS express API on MERN stack, acting as the project owner, reducing load times by more than 50%',
            'Designed web to phone bidirectional SMS messenger with delivery reports by consuming third party Twilio API along with exposing public and private webhooks, reducing class professor and student advisor workflows by 50%',
            'Designed Email tracking system with PHP, NodeJS and Couchbase to send emails to multiple recipients and track individual read receipts reducing a class professor’s workflow by 66%',
            'Designed API with NodeJS express middleware to save files in AWS S3 bucket, reducing load on application server while saving metadata in Couchbase increasing metadata fetch requests by 90%'
        ]
    },
    {
        icon: 'temple.svg',
        Employer: 'Temple University',
        Position: 'Software Engineer',
        from: 'Sept 2018',
        to: 'May 2020',
        summary: 'During my time at Temple University, Philadelphia PA, enrolled inthe master\'s program for Computer Science, I helped the researchers at Institute of Genomics and Evolutionary Medicine to automate the curation of studies submitted by researchers around the globe. In a span of 2 years, I helped them better understand their requirements and delivered an end to end solution to cater to their needs. I developed the full stack of the curation software with PHP, ReactJS and PostgreSQL which could process a tree of around 10 thousand scientefic species objects against the 3.2 million nodes of all known species in the National Library of Medicine taxonomy database ',
        details: [
            'Built a web application using PHP which stores research studies provided by users & metadata fetched with an API and stores it on Github as cloud storage, reducing the team workflow by 20%',
            'Designed a Fuzzy Matching algorithm in PHP which implements Levenshtein distance & database pipelining. Implemented database indexes to use index only queries reducing data fetch times by 77%',
            'Implemented master slave architecture of parallel processing for the fuzzy matching algorithm which terminates within 0.08 times of the select query execution time reducing application runtime by 94%',
            'Made the solution scalable by implementing Ajax throttling to overcome modern browsers limit of scheduling around only 2700 parallel Ajax requests at a time which prevents DOS attacks.',
        ]
    },
    {
        icon: 'tcs.svg',
        Employer: 'TATA Consultancy Services',
        Position: 'Assistant System Engineer',
        from: 'July 2016',
        to: 'June 2018',
        summary: 'TATA Consultancy Sevices introduced me to software consulting which relies heavily on stakeholder interaction and delivering solutions based on evolving requirements of the customer. First I contributed to the automation effort of British Telecom for their legacy system of contract renewals for postpaid landline service. We automaated the workflow and established data pipelines to support the new microservices architecture. Then, I was supporting the audit teams at Lloyds Bank UK with any database reports they needed on a weekly basis. The auditors would need a story to be put together and represented by data for which I was quering around 7 separate databases with 50+ tables each in SQLServer, with a complex query(s) comprising of subqueries, temporary views and stored proceedures',
        details: [
            'Programmed a POC in C# to automate multiple piped workflows reducing workflow time by 33%',
            'Automated monthly logging of multiple applications in a single yearly excel file using .NET framework for the client, supporting the piped workflow and reducing halts in the workflow by 75%',
            'Built SQL scripts to combine and fetch data by joining tables from different production databases together as per the requests from client side business analysts, reducing the team workflow by 25%',
        ]
    }
]

export const projects = [
    {
        name: 'Divorce prediction & marriage counseling with Neural Network',
        desc: 'Trained a neural net on a divorce questionnaire dataset based on a Likert scale survey with 98% accuracy. Learned core causes with feature selection and built an application around it which would check which features contributed to the prediction and suggest improving on correlated causes as counselling if divorce is predicted.',
        link: '#'
    },
    {
        name: 'Friend Suggestion algorithm',
        desc: 'Developed an algorithm to suggest friends by implementing the principle of triadic closure in an undirected and unweighted social graph. Also introduced a mutation effect with 1% random suggestion to connect undiscovered or isolated sections of the graph. Implemented a ranking system for the nodes suggested as friends based on the potential of number of social triangles closed.',
        link: 'https://github.com/nimitjohri2/OwlBook'
    },
    {
        name: 'Self-learning Blackjack algorithm with Q-Learning',
        desc: 'Trained an NPC in the game of Blackjack using Q-Learning, a type of Reinforced learning. Implemented a system to reward for good moves and penalize on bad moves and trained it by letting it play against dealer for 10k rounds. The trained model could make about 80% correct moves.',
        link: 'https://github.com/nimitjohri2/BlackJack_AI'
    },
    {
        name: 'Blockchain Linux mini kernel with consensus',
        desc: 'Developed a Linux based mini kernel operating system which can process Linux commands and supports piping while running as a distributed blockchain. The kernel would fork itself on any machine listening to a port within a subnet and keep a chain of logs to track executed and pending commands and accept a write to log if more than 50% nodes agree.',
        link: '#'
    }
]