import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Header from './js/components/layout/header'
import Introduction from './js/components/Introduction';
import About from './js/components/about';
import Experience from './js/components/experience';
import Journey from './js/components/journey';
import Project from './js/components/project';
import GameOver from './js/components/gameOver';


export default class App extends Component {

  state = {
    isMobile: window.innerWidth <= 1366,
    dropInstances: 50,
    layers: {},
    clicks: 0
  }

  componentDidMount(){
    this.createLayers()
  }

  createLayers = () => {
    const layers = [...new Array(this.state.dropInstances)].reduce((layers, current, id) => {
      return {
        ...layers,
        [id]: {
          offset: this.random(),
          speed: -this.random(),
          width: this.random()*100 + '%',
          marginLeft: this.random()*100 + '%',
          opacity: this.random(0, .25)*100 + '%'
        }
      }
    }, {})
    this.setState({layers})
  }

  click = () => {
    let clicks = this.state.clicks
    clicks++
    this.setState({clicks})
  }

  layerClicked = (id) => {
    let layers = this.state.layers
    layers[id].isClicked = true
    this.setState({layers})
  }

  random = (min=0, max=1) => {
    return Math.random() * (max - min) + min
  }

  render() {
    const isGameOver = Object.values(this.state.layers).every(layer => layer.isClicked)
    return <React.Fragment>
      {isGameOver ? <GameOver score={this.state.clicks + ' clicks used | perfect score: ' + this.state.dropInstances + ' clicks'} /> : 
      <Parallax onClick={this.click}>

        {/* {[...new Array(this.state.dropInstances)].map(id => <ParallaxLayer id={id} offset={this.random()} speed={-this.random()} style={{pointerEvents: 'none'}}><img src='/images/rain.png'  style={{ width: this.random()*100 + '%', marginLeft: this.random()*100 + '%', opacity: this.random(0, .25)*100 + '%' }}/></ParallaxLayer>)} */}
        {Object.values(this.state.layers).map((layer, id) => <ParallaxLayer onClick={() => this.layerClicked(id)} className={layer.isClicked ? 'parallax-layer-clicked' : ''} key={id} offset={layer.offset} speed={layer.speed}><img src='/images/rain.png'  style={{ width: layer.width, marginLeft: layer.marginLeft, opacity: layer.opacity }}/></ParallaxLayer>)}

        <Container fluid style={{fontSize: this.state.isMobile ? 'x-large': 'unset'}}>
          <Header />
          <Introduction />
          <Journey  />
          <Experience/>
          <Project />
          <About />
        </Container>
      </Parallax>}
      
  </React.Fragment>
  }
}
 