import React, { Component } from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { Card, CardBody, Col, Row } from 'reactstrap'

export default class Share extends Component {
  render() {
    return <React.Fragment>
        <Card className='simple'>
            <CardBody>
                <Row>
                    <Col xs={2} className='p-2'><FacebookShareButton url={window.location.hostname} children={<FacebookIcon round size={35} />} quote="Nimit Johri's portfolio" /></Col>
                    <Col xs={2} className='p-2'><LinkedinShareButton url={window.location.hostname} children={<LinkedinIcon round size={35} />} title="Nimit Johri's portfolio" /></Col>
                    <Col xs={2} className='p-2'><RedditShareButton url={window.location.hostname} children={<RedditIcon round size={35} />} title="Nimit Johri's portfolio"  /></Col>
                    <Col xs={2} className='p-2'><TwitterShareButton url={window.location.hostname} children={<TwitterIcon round size={35} />} title="Nimit Johri's portfolio"  /></Col>
                    <Col xs={2} className='p-2'><WhatsappShareButton url={window.location.hostname} children={<WhatsappIcon round size={35} />} title="Nimit Johri's portfolio"  /></Col>
                    <Col xs={2} className='p-2'><EmailShareButton url={window.location.hostname} children={<EmailIcon round size={35} />} subject="Nimit Johri's portfolio" /></Col>
                </Row>
            </CardBody>
        </Card>
    </React.Fragment>
  }
}
