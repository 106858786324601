import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Button, Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap'

export default class Header extends Component {
    state = {
        isOpen: false
    }

    toggle = () => this.setState({isOpen: !this.state.isOpen})

    render() {
    return <React.Fragment>
        <Navbar light color='white' container='fluid' expand='md' className='p-5 pt-2 pb-2' fixed='top'>
            <NavbarBrand href='/'><img src='/images/logo.png' height={'75vh'} /> Nimit Johri</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar justified>
                <Nav className='me-auto ml-auto container-fluid' horizontal='end' navbar>
                    <BrowserRouter>
                        <HashLink to='#journey'><NavItem><NavLink href='/'>Journey</NavLink></NavItem></HashLink>
                        <HashLink to='#experience'><NavItem><NavLink href='/'>Experience</NavLink></NavItem></HashLink>
                        <HashLink to='#projects'><NavItem><NavLink href='/'>Projects</NavLink></NavItem></HashLink>
                        <HashLink to='#about'><NavItem><NavLink href='/'>About</NavLink></NavItem></HashLink>
                        <Button color='primary'><a className='text-white' href='mailto:Nimit Johri<nimit.johri@temple.edu>?subject=Career opportunity' target='_blank'>Hire me!</a></Button>
                    </BrowserRouter>
                </Nav>
            </Collapse>
        </Navbar>
    </React.Fragment>
  }
}
