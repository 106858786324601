import React, { Component } from 'react'
import { Col, Row, UncontrolledPopover } from 'reactstrap'
import Share from './share'
import { links } from '../../content'

export default class Socials extends Component {
    state = {
        hover: ''
    }

    toggleHover = (hover) => this.setState({hover})
  
    render() {
    return <React.Fragment>
        <Row className='p-5 pb-3 pt-3 '>
          <Col><a href={links.linkedIn} target={'_blank'} onMouseEnter={() => this.toggleHover('linkedIn')} onMouseLeave={() => this.toggleHover('')}><i className={'fab fa-linkedin ' + (this.state.hover == 'linkedIn' ? 'fa-beat-fade fa-2xl text-primary' : ' text-dark')} /></a></Col>
          <Col><a href={links.facebook} target={'_blank'} onMouseEnter={() => this.toggleHover('facebook')} onMouseLeave={() => this.toggleHover('')}><i className={'fab fa-square-facebook ' + (this.state.hover == 'facebook' ? 'fa-beat-fade fa-2xl text-primary' : ' text-dark')} /></a></Col>
          <Col><a href={links.instagram} target={'_blank'} onMouseEnter={() => this.toggleHover('instagram')} onMouseLeave={() => this.toggleHover('')}><i className={'fab fa-instagram ' + (this.state.hover == 'instagram' ? 'fa-beat-fade fa-2xl text-danger' : ' text-dark')} /></a></Col>
          <Col><a href={links.gitHub} target={'_blank'} onMouseEnter={() => this.toggleHover('github')} onMouseLeave={() => this.toggleHover('')}><i className={'fab fa-github ' + (this.state.hover == 'github' ? 'fa-beat-fade fa-2xl text-secondary' : ' text-dark')} /></a></Col>
          <Col><span id='share' role='button' onMouseEnter={() => this.toggleHover('share')} onMouseLeave={() => this.toggleHover('')}><i className={'fas fa-share ' + (this.state.hover == 'share' ? 'fa-beat-fade fa-2xl text-secondary' : ' text-dark')} /></span></Col>
          <UncontrolledPopover target={'share'} trigger='hover focus' placement='bottom'>
            <Share />
        </UncontrolledPopover>
      </Row>
    </React.Fragment>
  }
}
