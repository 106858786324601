import React, { Component } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { projects } from '../content'

export default class Project extends Component {
  render() {
    return <React.Fragment>
        <Container fluid className='text-center p-5' id='projects'>
            <Row><Col><h3>Projects</h3></Col></Row>
            <Row>
                {projects.map((project, index) => <Col xxl={6} lg={12} key={index}>
                    <Card className='mb-3'>
                        <CardBody>
                            <Row><Col><h3>{project.name}</h3></Col></Row>
                            <Row><Col><p>{project.desc}</p></Col></Row>
                            <Row><Col><a href={project.link} target='_blank' >Repository</a></Col></Row>
                        </CardBody>
                    </Card>
                </Col>)}
            </Row>
        </Container>
    </React.Fragment>
  }
}
