import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Lottie from "lottie-react";
import trophy from '../../assets/trophy.json'
import confetti from '../../assets/confetti.json'

export default class GameOver extends Component {
    state = {
        confettiCount: 10,
        isConfetti: true
    }

    componentDidMount(){
        this.cleanUp()
    }

    random = (min=0, max=1) => {
        return Math.random() * (max - min) + min
    }

    cleanUp = () => {
        setTimeout(() => {
            this.setState({isConfetti: false})
        }, 2*1000); 
    }

  render() {
    return <React.Fragment>
        {this.state.isConfetti && <div>
            {[...new Array(this.state.confettiCount)].map((instance, id) => <div style={{position: 'absolute', top: this.random()*1000 + 'px', left: this.random()*1000 + 'px'}}><Lottie animationData={confetti} loop={false} /></div>)}
        </div>}
        <div className='game-over'>
            <Row><Col>Congratulations! You discovered the secret and finished the game</Col></Row>
            <Row><Col>{'score: ' + this.props.score}</Col></Row>
            <Row><Col><Lottie animationData={trophy} loop={false}/></Col></Row>
            <Row><Col><Button style={{zIndex: 1}} outline color='primary' onClick={() => window.location.reload()}>Reload</Button></Col></Row>
        </div>
    </React.Fragment>
  }
}
