import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap'
import { experiences } from '../content'

export default class Experience extends Component {
    state = {
        active: -1
    }

    activate = (active) => this.setState({active: this.state.active == active ? -1 : active})

  render() {
    return <React.Fragment>
        <Container fluid className='text-center p-5 ' id='experience'>
            <Row className='zoom'><Col><h3>Professional Experience</h3></Col></Row>
            <Row>
                <Col xxl={2} lg={1}></Col>
                <Col xxl={8} lg={10}>
                    {experiences.map((experience, index) => <Card className={'mb-3 ' + (this.state.active == index ? 'expanded' : '')} key={index} >
                        <CardBody>
                                <Row><Col className='align-self-center'><img src={'/images/' + experience.icon} height={'200vw'} /></Col></Row>
                                <Row><Col>
                                    <Row><Col><h3>{experience.Employer}</h3></Col></Row>
                                    <Row><Col><h5>{experience.Position + ' | ' + experience.from + ' - ' + experience.to}</h5></Col></Row>
                                    <Row><Col><p>{experience.summary}</p></Col></Row>
                                    <Row><Col>
                                        <Collapse isOpen={this.state.active == index}>
                                            <h6>Achievements</h6>
                                            <ul className='p-5 pt-3 pb-3' style={{textAlign: 'left'}}>
                                                {experience.details.map((detail, id) => <li key={id}>{detail}</li>)}
                                            </ul>
                                        </Collapse>
                                    </Col></Row>
                                    <Row><Col style={{textAlign: 'right'}}><Button outline color='primary' onClick={() => this.activate(index)}>{(this.state.active == index ? 'Collapse' : 'Learn More') }</Button></Col></Row>
                                </Col></Row>
                        </CardBody>
                    </Card>)}    
                </Col>
                <Col xxl={2} lg={1}></Col>
            </Row>
        </Container>
    </React.Fragment>
  }
}
