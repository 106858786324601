import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap'
import { summary } from '../content'
import Socials from './utils/socials'

export default class About extends Component {
    state = {
        isMore: false
    }

  toggleMore = () => this.setState({isMore: !this.state.isMore})

  render() {
    return <React.Fragment>
      <Container fluid className='text-center p-5' id='about'>
        <Row><Col><h3>About me</h3></Col></Row>
        <Row>
          <Col xxl={2} lg={1}></Col>
          <Col xxl={8} lg={10}>
            <Card>
              <CardBody>
                <Row className='p-5 pt-2 align-items-center text-center'>
                  <Col md={4}>
                    <Row><Col><img src='/images/Headshot.JPG' alt='Nimit Johri' width={'100%'} style={{borderRadius: '10%'}}/></Col></Row>
                    <Row><Col><Socials /></Col></Row>
                  </Col>
                  <Col md={8}>
                    <Row><Col>
                      <Row><Col>{<p>{summary.paragraphs[0]}</p>}</Col></Row>
                      <Row><Col>
                        <Collapse isOpen={this.state.isMore}>
                          {summary.paragraphs.map((para, index) => index == 0 ? '' : <p key={index}>{para}</p>)}
                        </Collapse>
                      </Col></Row>
                    </Col></Row>
                    <Row><Col><Button outline color='primary' onClick={this.toggleMore}>{'Show ' + (this.state.isMore ? 'Less' : 'More')}</Button></Col></Row>
                    <Row><Col className='p-2'><b>Contact: </b></Col></Row>
                    <Row><Col>{'email: '} <a target={'_blank'} href={'mailto:' + summary.contact.email}>{summary.contact.email}</a></Col></Row>
                    <Row><Col>{'phone: '} <a target={'_blank'} href={'tel:' + summary.contact.phone}>{summary.contact.phone}</a></Col></Row>
                    <Row><Col className='p-2'><b>Resume</b></Col></Row>
                    <Row><Col><a href='/assets/NimitJohri_Resume.pdf' target='_blank' type='application/pdf' content='application/pdf' >Nimit Johri Resume</a></Col></Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={2} lg={1}></Col>
        </Row>
      </Container>
    </React.Fragment>
  }
}
