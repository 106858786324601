import React, { Component } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { intro } from '../content'
import Socials from './utils/socials'

export default class Introduction extends Component {

  render() {
    return <React.Fragment>
      <Container className='text-center p-5 pb-0 mt-4 introduction'>
        <Card className='simple'>
          <CardBody>
            <Row className='p-5 pt-2 pb-2 align-items-center text-center'>
              <Col xxl={4} lg={1}></Col>
              <Col xxl={4} lg={10}>
                <Row><Col><img src='/images/outdoor.jpg' alt='Nimit Johri' height={'250vh'} style={{borderRadius: '50%'}}/></Col></Row>
                <Row><Col className='mt-2'><h3><i>Hey! I'm Nimit</i></h3></Col></Row>
                <Row><Col>{intro}</Col></Row>
                <Row><Col><Socials /></Col></Row>
              </Col>
              <Col xxl={4} lg={1}></Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  }
}
