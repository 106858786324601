import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'

export default class Journey extends Component {
  render() {
    return <React.Fragment>
        <Container fluid className='text-center p-5 position-relative' id='journey' >
            <Row><Col><h3>My Journey</h3></Col></Row>
            <iframe src='https://flo.uri.sh/story/2005918/embed' title='Interactive or visual content' className='flourish-embed-iframe' frameBorder='0' scrolling='no' style={{width: '100%', height: '600px'}} sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe>
            {/* <div style={{width:'100%!', marginTop:'4px!important', textAlign:'right!important'}}>
                <a class='flourish-credit' href='https://public.flourish.studio/story/2005918/?utm_source=embed&utm_campaign=story/2005918' target='_top' style={{textDecoration:'none!important'}}>
                    <img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style={{width:'105px!important', height: '16px!important', border: 'none!important', margin:'0!important'}} />
                </a>
            </div> */}
        </Container>
    </React.Fragment>
  }
}